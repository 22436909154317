import React, { useRef, useEffect, useContext, useState } from 'react';
import { Link, Navigate, useNavigate } from 'react-router-dom';
import { AppSettings } from './../../config/app-settings.js';
import { Tooltip } from "react-tooltip";
import { register } from '../../features/auth/authActions.js';
import { useDispatch, useSelector } from "react-redux";

import { setUser } from '../../features/auth/authSlice'

const USER_REGEX = /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/;
const PWD_REGEX = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%]).{12,24}$/;
const USERNAME_REGEX = /^[a-zA-Z0-9_.+-]{3,}$/;

function PagesRegister() {
	const location = useSelector(state => state.auth.location);
	const dispatch = useDispatch();
	const history = useNavigate();

	const context = useContext(AppSettings);
	const [redirect, setRedirect] = useState(false);

	const userRef = useRef();
	const errRef = useRef();

	const [email, setEmail] = useState('');
	const [validName, setValidName] = useState(false);
	const [userFocus, setUserFocus] = useState(false);

	const [username, setUsername] = useState('');
	const [validUsername, setValidUsername] = useState(false);
	const [usernameFocus, setUsernameFocus] = useState(false);

	const [password, setPwd] = useState('');
	const [validPwd, setValidPwd] = useState(false);
	const [pwdFocus, setPwdFocus] = useState(false);

	const [password_confirm, setMatchPwd] = useState('');
	const [validMatch, setValidMatch] = useState(false);
	const [matchFocus, setMatchFocus] = useState(false);
	const [errMsg, setErrMsg] = useState('');


	useEffect(() => {
		context.setAppHeaderNone(true);
		context.setAppSidebarNone(true);
		context.setAppContentClass('p-0');

		return function cleanUp() {
			context.setAppHeaderNone(false);
			context.setAppSidebarNone(false);
			context.setAppContentClass('');
		};

		// eslint-disable-next-line
	}, []);

	useEffect(() => {
		userRef.current.focus();
	}, [])

	useEffect(() => {
		setValidName(USER_REGEX.test(email));
	}, [email])

	useEffect(() => {
		setValidUsername(USERNAME_REGEX.test(username));
	}, [username])

	useEffect(() => {
		setValidPwd(PWD_REGEX.test(password));
		setValidMatch(password === password_confirm);
	}, [password, password_confirm])

	useEffect(() => {
		setErrMsg('');
	}, [email, username, password, password_confirm])

	async function handleSubmit(event) {
		event.preventDefault();

		const v1 = USER_REGEX.test(email);
		const v2 = PWD_REGEX.test(password);
		const v3 = USERNAME_REGEX.test(username);
		if (!v1 || !v2 || !v3) {
			setErrMsg("Invalid Entry");
			return;
		}

		dispatch(register(email, username, password, password_confirm)).then(() => {
			// Dirty fix after dispatch successfull
			// PersistLogin should have a hooks or something that should be called to check the state after dispatch
			// dispatch register should handle persistLogin
			// the state loop should be well contained and closed.
			const storeToken = localStorage.getItem('storetoken') ? JSON.parse(localStorage.getItem('storetoken')) : {};
			dispatch(setUser({ ...storeToken }));
			history(location);
		})
		setRedirect(true);
	}

	if (redirect) {
		return <Navigate to='/' />;
	}
	return (
		<div className="register">
			<div className="register-content">
				<form onSubmit={handleSubmit}>
					<h2 className="text-center" style={{ color: "#FFA500" }}> WARFARE.ZONE  </h2>
					<br />
					<h1 className="text-center">Sign Up</h1>
					<p className="text-white text-opacity-50 text-center">Please provide your email address for registration.</p>
					<div className="mb-3">
						<label htmlFor="email" className="form-label">Email Address <span className="text-danger">*</span></label>
						<input type="text"
							id="email"
							ref={userRef}
							autoComplete="off"
							onChange={(event) => setEmail(event.target.value)}
							value={email}
							required
							aria-invalid={validName ? "false" : "true"}
							aria-describedby="email"
							onFocus={() => setUserFocus(true)}
							onBlur={() => setUserFocus(false)}
							className="form-control form-control-lg bg-white bg-opacity-5"
							placeholder="user@domain.com"
							data-tooltip-variant={userFocus && email && !validName ? "error" : "dark"}
						/>
						<Tooltip anchorId="email" style={{ width: "70%" }} content="Please enter valid email address."
							className={userFocus && email && !validName ? "error" : "dark"}
							place="right" />
					</div>

					<div className="mb-3">
						<label htmlFor="username" className="form-label">Username <span className="text-danger">*</span></label>
						<input type="text"
							id="username"
							onChange={(event) => setUsername(event.target.value)}
							value={username}
							required
							aria-invalid={!validUsername ? "true" : "false"}
							aria-describedby="username"
							onFocus={() => setUsernameFocus(true)}
							onBlur={() => setUsernameFocus(false)}
							className="form-control form-control-lg bg-white bg-opacity-5"
							placeholder="Enter username"
							data-tooltip-variant={usernameFocus && username && !validUsername ? "error" : "dark"}
						/>
						<Tooltip anchorId="username" style={{ width: "70%" }} content="Username must be at least 3 characters."
							className={usernameFocus && username && !validUsername ? "error" : "dark"} place="right" />
					</div>



					<div className="mb-3">
						<label htmlFor="password" className="form-label">Password <span className="text-danger">*</span></label>
						<input type="password"
							id="password"
							onChange={(event) => setPwd(event.target.value)}
							value={password}
							required
							aria-invalid={validPwd ? "false" : "true"}
							aria-describedby="password"
							onFocus={() => setPwdFocus(true)}
							onBlur={() => setPwdFocus(false)}
							className="form-control form-control-lg bg-white bg-opacity-5" placeholder="Passowrd"
							data-tooltip-variant={pwdFocus && !validPwd ? "error" : "dark"}
						/>
						<Tooltip anchorId="password" style={{ width: "70%" }} content="Use Min 12 and Max 24 characters.
                                                                Must include uppercase and lowercase letters, a number and a special character."
							className={pwdFocus && !validPwd ? "error" : "dark"} place="right" />
					</div>
					<div className="mb-3">
						<label htmlFor="confirm_pwd" className="form-label">Confirm Password <span className="text-danger">*</span></label>
						<input type="password"
							id="confirm_pwd"
							onChange={(event) => setMatchPwd(event.target.value)}
							value={password_confirm}
							required
							aria-invalid={validMatch ? "false" : "true"}
							aria-describedby="confirm_pwd"
							onFocus={() => setMatchFocus(true)}
							onBlur={() => setMatchFocus(false)}
							className="form-control form-control-lg bg-white bg-opacity-5" placeholder="Repeat Passowrd"
							data-tooltip-variant={matchFocus && !validMatch ? "error" : "dark"}
						/>
						<Tooltip anchorId="confirm_pwd" style={{ width: "70%" }} content="Please repeat your password."
							className={matchFocus && !validMatch ? "error" : "dark"} place="right" />
					</div>
					{/* 
					<div className="mb-3">
						<label className="form-label">Gender <span className="text-danger">*</span></label>
						<select className="form-select form-select-lg bg-white bg-opacity-5">
							<option>Female</option>
							<option>Male</option>
						</select>
					</div>
					 */}
					<div className="mb-3">
						<div className="form-check">
							<input className="form-check-input" type="checkbox" id="customCheck1" />
							<label className="form-check-label" htmlFor="customCheck1">I have read and agree to the <Link to="/legal/terms-of-use">Terms of Use</Link> and <Link to="/legal/privacy-policy">Privacy and Cookies Policy</Link>.</label>
						</div>
					</div>
					<div className="mb-3">
						<button disabled={!validName || !validPwd || !validMatch || !validUsername ? true : false} type="submit" className="btn btn-outline-theme btn-lg d-block w-100">Sign Up</button>
					</div>
					<div className="text-white text-opacity-50 text-center">
						Already a Soldier? <Link to="/auth/login">Sign In</Link>
					</div>
				</form>
			</div>
		</div>
	)
}

export default PagesRegister;
