import React, { useRef, useEffect, useContext, useState } from 'react';
import { Tooltip } from "react-tooltip";
import { Link, Navigate, useNavigate } from 'react-router-dom';
import { AppSettings } from '../../config/app-settings.js';
import { reset } from '../../features/auth/authActions.js';
import { useDispatch, useSelector } from "react-redux";

const USER_REGEX = /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/;

function PagesReset() {
	const location = useSelector(state => state.auth.location)
	const context = useContext(AppSettings);
	const [redirect, setRedirect] = useState(false);
	const [email, setEmail] = useState('');

	const userRef = useRef();
	const [validName, setValidName] = useState(false);
	const [userFocus, setUserFocus] = useState(false);
	const [errMsg, setErrMsg] = useState('');

	//const [password, setPassword] = useState('');
	// const [role, setRole] = useState('')
	const dispatch = useDispatch();
	const history = useNavigate()
	console.log("reset location", location);

	useEffect(() => {
		context.setAppHeaderNone(true);
		context.setAppSidebarNone(true);
		context.setAppContentClass('p-0');

		return function cleanUp() {
			context.setAppHeaderNone(false);
			context.setAppSidebarNone(false);
			context.setAppContentClass('');
		};

		// eslint-disable-next-line
	}, []);

	useEffect(() => {
		setValidName(USER_REGEX.test(email));
	}, [email])

	useEffect(() => {
		setErrMsg('');
	}, [email])

	async function handleSubmit(event) {
		event.preventDefault();

		const v1 = USER_REGEX.test(email);
		if (!v1) {
			setErrMsg("Invalid Entry");
			return;
		}

		dispatch(reset(email)).then(() => {
			history(location)
		})

		setRedirect(true);
	}

	if (redirect) {
		return <Navigate to={'/'} />;
	}
	return (
		<div className="login">
			<div className="login-content">
				<form onSubmit={handleSubmit}>
					<h2 className="text-center" style={{ color: "#FFA500" }}> WARFARE.ZONE  </h2>
					<br />
					<h1 className="text-center">Reset Password</h1>
					<br />
					<div className="text-white text-opacity-50 text-center mb-4">
						What is your ID soldier? <br />
					</div>
					<div className="mb-3">
						<label htmlFor="email" className="form-label">Email Address <span className="text-danger">*</span></label>
						<input type="text"
							id="email"
							ref={userRef}
							autoComplete="off"
							name="email"
							className="form-control form-control-lg bg-white bg-opacity-5"
							placeholder="Email Address"
							onChange={(e) => { setEmail(e.target.value) }}
							value={email}
							required
							aria-invalid={validName ? "false" : "true"}
							aria-describedby="email"
							onFocus={() => setUserFocus(true)}
							onBlur={() => setUserFocus(false)}
							data-tooltip-variant={userFocus && email && !validName ? "error" : "dark"}
						/>
						<Tooltip anchorId="email" style={{ width: "70%" }} content="Please enter your email address."
							className={userFocus && email && !validName ? "error" : "dark"}
							place="right" />
					</div>
					<button disabled={!validName ? true : false} type="submit" className="btn btn-outline-theme btn-lg d-block w-100 fw-500 mb-3">Reset Password</button>
					<div className="text-center text-white text-opacity-50">
						Already a Soldier? <Link to="/auth/login">Sign In</Link>
					</div>
					<div className="text-center text-white text-opacity-50">
						Not a Soldier yet? <Link to="/auth/register">Sign Up</Link>
					</div>
				</form>
			</div>
		</div>
	)
}

export default PagesReset;
